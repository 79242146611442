import router from '@/router'
import store from '@/store'

// 白名单
const whiteList = ['/login']

/**
 * 前置路由守卫
 * to 要到哪里去
 * from 你从哪里来
 * next 是否要去
 */
router.beforeEach(async (to, from, next) => {
  // document.title = to.meta.title ? to.meta.title : 'home'
  // if(store.state.user.token)
  if (store.getters.token) {
    // 1.用户已登录即存在token，则不允许进入login
    if (to.path === '/login') {
      next('/')
    } else {
      next()
    }
  } else {
    // 2.用户未登录（无token的情况下），只允许进入login，404页面（白名单）
    if (whiteList.indexOf(to.path) > -1) {
      next()
    } else {
      next('/login')
    }
  }
})
