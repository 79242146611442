import layout from '@/layout'

export default {
  path: '/account',
  component: layout,
  meta: {
    title: '小程序账号管理',
    icon: 'account_mini'
  },
  children: [
    {
      path: '',
      name: 'account',
      component: () => import('@/views/account/index')
    }
  ]
}
