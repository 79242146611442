import layout from '@/layout'

export default {
  path: '/exchange',
  component: layout,
  meta: {
    title: '兑换码管理',
    icon: 'exchange'
  },
  children: [
    {
      path: '',
      name: 'exchangeManage',
      component: () => import('@/views/exchange/index')
    }
  ]
}
