import layout from '@/layout'

export default {
  path: '/payment',
  component: layout,
  meta: {
    title: '支付管理',
    icon: 'payment'
  },
  children: [
    {
      path: '',
      name: 'paymentManage',
      component: () => import('@/views/payment/index')
    }
  ]
}
