import { watch } from 'vue'
import store from '@/store'

export function generateTitle(title) {
  return title
}

/**
 * 监听语言的变化
 * @param  {...any} cbs 所有的回调
 */
export function watchSwitchLang(...cbs) {
  watch(
    () => store.getters.language,
    () => {
      cbs.forEach(cb => cb(store.getters.language))
    }
  )
}
