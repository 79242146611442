import layout from '@/layout'

export default {
  path: '/activity',
  component: layout,
  meta: {
    title: '活动管理',
    icon: 'activity'
  },
  children: [
    {
      path: '',
      name: 'activityManage',
      component: () => import('@/views/activity/index')
    }
  ]
}
