import { createRouter, createWebHashHistory } from 'vue-router'
import layout from '@/layout/index'
import Account from './modules/Account'
import Activity from './modules/Activity'
import Payment from './modules/Payment'
import Exchange from './modules/Exchange'
import Order from './modules/Order'
import store from '@/store'

// export const privateRoutes = [
//   RoleListRouter,
//   UserManageRouter,
//   PermissionListRouter,
//   ArticleCreaterRouter,
//   ArticleRouter
// ]

export const privateRoutes = [Activity, Exchange, Order, Account, Payment]

/**
 * 公开路由表
 */
export const publicRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/index')
  },
  {
    path: '/',
    // 注意：带有路径“/”的记录中的组件“默认”是一个不返回 Promise 的函数
    component: layout,
    redirect: '/product',
    children: [
      {
        path: '/product',
        name: 'product',
        component: () => import('@/views/product/index'),
        meta: {
          title: '产品管理',
          icon: 'product'
        }
      },
      {
        path: '/404',
        name: '404',
        component: () => import('@/views/error-page/404')
      },
      {
        path: '/401',
        name: '401',
        component: () => import('@/views/error-page/401')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: [...publicRoutes, ...privateRoutes]
})

/**
 * 初始化路由表
 */
export function resetRouter() {
  if (
    store.getters.userInfo &&
    store.getters.userInfo.permission &&
    store.getters.userInfo.permission.menus
  ) {
    const menus = store.getters.userInfo.permission.menus
    menus.forEach(menu => {
      router.removeRoute(menu)
    })
  }
}

export default router
