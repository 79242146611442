<template>
  <div class="">
    <el-scrollbar>
      <div class="logo-container">
        <el-avatar
          :size="logoHeight"
          shape="square"
          src="https://img.alicdn.com/imgextra/i1/O1CN01EI93PS1xWbnJ87dXX_!!6000000006451-2-tps-150-150.png"
        />
        <h1 class="logo-title" v-if="$store.getters.sidebarOpened">
          支付助手管理后台
        </h1>
      </div>
      <sidebar-menu></sidebar-menu>
    </el-scrollbar>
  </div>
</template>

<script setup>
import SidebarMenu from './SidebarMenu'
import {} from 'vue'

const logoHeight = 44
</script>

<style lang="scss" scoped>
.logo-container {
  height: v-bind(logoHeight) + 'px';
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .logo-title {
    margin-left: 5px;
    color: #303133;
    font-weight: 700;
    font-size: 18px;
    white-space: nowrap;
  }
  .el-avatar {
    --el-avatar-background-color: none;
  }
}
</style>
