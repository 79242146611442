<template>
  <div class="navbar">
    <div class="left-menu">
      <hamburger class="hamburger-container" />
      <breadcrumb id="guide-breadcrumb" class="breadcrumb-container" />
    </div>
    <div class="right-menu">
      <!-- <header-search class="right-menu-item hover-effect"></header-search> -->
      <screenfull class="right-menu-item hover-effect" />
      <!-- 头像 -->
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper">
          <!-- <el-avatar
            shape="square"
            :size="40"
            :src="$store.getters.userInfo.avatar"
          ></el-avatar> -->
          <el-avatar
            shape="square"
            :size="40"
            src="https://img.alicdn.com/imgextra/i1/O1CN01EI93PS1xWbnJ87dXX_!!6000000006451-2-tps-150-150.png"
          ></el-avatar>
        </div>
        <template #dropdown>
          <el-dropdown-menu class="user-dropdown">
            <el-dropdown-item> super-admin </el-dropdown-item>
            <el-dropdown-item divided @click="logout">
              退出登录
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import Hamburger from '@/components/Hamburger'
import Breadcrumb from '@/components/Breadcrumb'
import Screenfull from '@/components/Screenfull'
// import HeaderSearch from '@/components/HeaderSearch'

const store = useStore()
const logout = () => {
  store.dispatch('user/logout')
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  position: relative;
  background: #fff;
  border-bottom: 1px solid #e4e7ed;

  .left-menu {
    display: flex;
    align-items: center;
    .hamburger-container {
      cursor: pointer;
      // hover 动画
      transition: background 0.5s;

      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }

  .right-menu {
    display: flex;
    align-items: center;
    padding-right: 16px;

    :deep(.avatar-container) {
      cursor: pointer;
      .avatar-wrapper {
        position: relative;
        .el-avatar {
          --el-avatar-background-color: none;
        }
      }
    }

    :deep(.right-menu-item) {
      display: inline-block;
      padding: 0 18px 0 0;
      font-size: 24px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
      }
    }
  }

  .breadcrumb-container {
    float: left;
  }
}
</style>
