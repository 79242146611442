import layout from '@/layout'

export default {
  path: '/order',
  component: layout,
  meta: {
    title: '订单管理',
    icon: 'order'
  },
  children: [
    {
      path: '',
      name: 'order',
      component: () => import('@/views/order/index')
    }
  ]
}
